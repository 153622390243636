.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wrapper {
  display: flex;
  flex-direction: column;
  animation-name: arrow;
  animation-duration: 1500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.wrapper-image {
  width: 10px;
  height: 6px;
}

@keyframes arrow {
  from {
    flex-direction: column;
  }

  to {
    flex-direction: column-reverse;
  }
}
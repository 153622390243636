.canvas {
  height: 100vh;
  background-color: #0C3A68;
  width: 100%;
  position: absolute;
}

.canvas_content {
  height: 300px;
  background-color: #0C3A68;
  width: 100%;
  position: absolute;
}